.BlogCard {
    aspect-ratio: 4/5;
    padding: 1rem;
    border: 1px solid transparent;
    transition: box-shadow 0.3s ease;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    position: relative;
}

.BlogCard_Bottom {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.BlogCard_Bottom > span {
    color: rgb(158, 158, 158);
}

.BlogCard_Top {
    height: 70%;
    width: 100%;
    overflow: hidden;
}

.BlogCard_Top > img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.BlogCard:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.51);
}
