


.whoweserve-container {
    width: 100%;
    height: auto;
    padding: 2rem 0 5rem 0;
    background-color: white;;
}

.whoweserve {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.whoweserve-top {

}

.whoweserve-top > h1 {

}

.whoweserve-bottom {

}


.whoweserve-bottom > p{

}
