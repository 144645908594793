.testimonials-container {
    width: 100%;
    height: auto;
    padding: 2rem 0 5rem 0;
    background-color:  #f2f2f2;

}

.testimonials {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.testimonials-top {
    margin-bottom: 1rem;
}

.testimonials-bottom {
    display: flex;
    flex-direction: column;
}

.testimonials-card {
    width: 50%;
}

.testimonials-bottom div:nth-child(1) {
    align-self: flex-start;
}

.testimonials-bottom div:nth-child(2) {
    align-self: flex-end;
}

.testimonials-bottom div:nth-child(3) {
    align-self: flex-start;
}
.testimonials-bottom div:nth-child(4) {
    align-self: flex-end;
}

.testimonials-bottom div:nth-child(5) {
    align-self: flex-start;
}


@media (max-width: 430px) {

    .testimonials-card {
        align-self: center !important;
        width: 100%;
        padding: 1rem 1rem 2.8rem 1rem;
    }
}


.testimonials-card div {
    display: flex;
}

.testimonials-card p {
    font-size: 0.9rem
}

.testimonials-card div div {
    display: flex;
    flex-direction: column;
    margin-left: 0.6rem
}

.testimonials-logo {
    width: 180px;
    height: auto;
}

.testimonial-img {
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.testimonials-card div div span {
    font-size: 0.8rem;
    color:#6f6f6f
}
