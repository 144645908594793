.selected_blog_container {
    width: 60%;
    margin: auto;
    background-color: white;
    padding-bottom: 5rem;
    margin-bottom: 2rem;
}

.selected_blog_top {
    width: 80%;
    margin: auto;
    padding: 4rem 1rem 2rem 1rem;
}

.selected_blog_top > h1 {
    margin: 0;
}

.selected_blog_top > span {
    color: rgb(122, 122, 122);
}

.selected_blog_img_container {
    width: 70%;
    margin: auto;
    padding: 2rem 0 1rem 0;
}

.selected_blog_img_container > img {
    object-fit: fill;
    width: 100%;
    height: 100%;
}

.selected_blog_bottom {
    width: 80%;
    margin: auto;
}

.selected_blog_bottom > p {
    white-space: pre-line;

}
