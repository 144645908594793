#contact-form_container {
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contact-form {
    width: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    background-color: rgb(242, 242, 242);
    gap: 1rem;
}


.contact_form_fields {
    width: 100%;
}

.contact_form_fields > * {
    margin: 10px 0;
}

#name, #email {
    padding: 10px;
    width: 42%;
    border: 1px solid rgb(182, 182, 182);
}

.field_half {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.message_field > textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid rgb(182, 182, 182);
}

#name:focus, #email:focus, #message:focus {
    border: 1px solid rgb(101, 101, 101);
}

.error {
    color: red;
    font-size: 12px;
    margin: 2px 0;
}

#submit_btn {
    border: none;
    height: 2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: rgb(163, 163, 163);
    font-weight: 'bold'
}

input[type="submit"]:active {
    background-color: rgb(108, 222, 108);
    color: rgb(255, 255, 255);
    transition: none;
}


#submit_btn:hover {
    background-color: rgb(173, 226, 173);
    color: rgb(255, 255, 255);
}

@media (max-width: 537px) {
    .field_half {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    #name, #email {
        padding: 10px;
        width: auto;
    }
    #contact-form_container {
        width: 100%;
    }

}

.contact_form_dropdown {
    position: relative;
    width: 100%;
}

#dropdown_items {
    position: absolute;
    background-color: white;
    color: black;
    width: 100%;
}

#dropdown_items > div {
    display: flex;
    flex-direction: column;
    width: calc(96% -1px);
    padding: 2%;
    font-size: 13px;
    gap: 0.2rem;
    border: 1px solid rgb(146, 146, 146);
    border-top: none;
}

#dropdown_items > div > label {
    display: flex;
    gap: 0.4rem;
    color: rgb(57, 57, 57)
}

.hidden {
    display: none;
}

#dropdown_select_container {
    width: 100%;
    display: flex;
}

#selected_services {
    width: 93%;
    border: 1px solid rgb(182, 182, 182);
    padding: 5px 10px;
    cursor: default;
}

#dropdown_button {
    width: 7%;
    border: 1px solid rgb(182, 182, 182);
    border-left: none;
    cursor: pointer;
    text-align: center;
}
