.service_card_main_top {
    width: 100%;
    height: 80%;
}

.service_card_main_top > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.service_card_main_bottom {
    width: 100%;
    height: 20%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
}

.service_card_main_bottom > span {
    font-weight: bold;
}

.service_card_container {
    width: 300px;
    /* transition: box-shadow 2s ease; */
    transition: border-radius 0.6s ease;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.service_card_container:hover {
    border-radius: 0px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.51);
}
