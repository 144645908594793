.navbar {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0 auto;
    position: sticky;
    background-color: #ffffff;
    z-index: 99999;
    width: 80%;
    /* max-width: 80rem; */
}

.navbar_img img {
    width: 50px;
    height: auto;
}


.logo_title {
    font-size: 1.3rem !important;
    font-family: AmaticBold !important;
}

.navbar_img a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.navbar_links {
    display: flex;
    list-style: none;
    gap: 2.1rem;
    cursor: pointer
}

/* Media query for screens 562px wide or less */
@media (max-width: 562px) {
    .navbar_links {
      font-size: 12px; /* Adjust the font size for smaller screens */
      gap: 1rem;
    }
}


.navbar_links > li > a {
    color: rgb(118, 118, 118);
    transition: 0.2s all ease
}

.navbar_links > li > a:hover {
    color: rgb(34, 34, 34)
}

nav {
    display: block;
    width: 100%;
    border-bottom: 1px solid rgb(223, 223, 223);
    background-color: #ffffff;
}
