.MainBlog-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: white;
}

.MainBlog-Top {
    width: 70%;
    margin: 5rem 0;
}

.Most_Recent_Blog_Container {
    width: 100%;
    transition: box-shadow 0.3s ease;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 1rem 0;
    border-radius: 5px;
    background-color: white;
    height: auto;
}

.Most_Recent_Blog_Container:hover {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.51);
}

.MainBlog-Bottom {
    height: auto;
    width: 70%;
    display: grid;
    grid-gap: 23px;
    padding-bottom: 3rem;
    justify-items: center;
    align-items: center;
    margin-bottom: 3rem;
}

.Most_Recent_Blog_Card {
    display: flex;
    padding: 0rem 2rem;
    width: 100%;
    border-radius: 15px;
}

.Most_Recent_Blog_Card_Left {
    width: 20%;
    margin: 1rem;
}

.Most_Recent_Blog_Card_Left > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.Most_Recent_Blog_Card_Right {
    width: calc(80% - 6rem);
    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Most_Recent_Blog_Card_Right > span:nth-child(2) {
  color: rgb(91, 91, 91)
}

.Most_Recent_Blog_Card_Right > h1 {
    margin-top: 0
}

.Most_Recent_Blog_Card_Right > span:nth-child(4) {
    margin-right: 1rem;
    text-decoration: underline;
    color: rgb(22, 189, 214);
}

@media (min-width: 2400px) {
    .MainBlog-Bottom {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  @media (max-width: 2400px) {
    .MainBlog-Bottom {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  @media (max-width: 2000px) {
    .MainBlog-Bottom {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 1600px) {
    .MainBlog-Bottom {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 1200px) {
    .MainBlog-Bottom {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 600px) {
    .MainBlog-Bottom {
      grid-template-columns: 1fr;
    }
  }
