.services_page_main {
    display: flex;
    margin: auto;
    justify-content: center;
}

.services_sidebar {
    padding: 10rem 0rem;
}

.services_sidebar > li {
    padding-bottom: 1rem;
    cursor: pointer;
}

.services_card_container {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    width: 60%;
    padding: 6rem 0;
    justify-content: center;
    background-color: white;
}

.services_page_container {
    background-color: white;
    position: relative;
    flex-grow: 1;
}


.clickable {
    color: gray;
    transition: color 0.3s ease;
}


.clickable:hover {
    color: rgb(0, 0, 0)
}

.clicked {
    color: black
}
