.footer-container {
    width: 100%;
    height: auto;
    padding: 5rem 0 5rem 0;
    background-color: #333333;
    color: white;
}

.footer {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.footer-top {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.columns {
    margin: 0 1rem;
}

.footer-top ul {
    padding: 0
}

.footer-top li {
    cursor: pointer;
    font-size: 0.8rem;
    margin-bottom: 5px;
    color:#9e9e9e;
    transition: all 0.2s ease
}

.footer-top li:hover {
    color:white
}

.social-links {
    display: flex;
    padding: 1rem 0;
    justify-content: center;
    align-items: center;
}

.social-links i {
    font-size: 28px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: white;
}

.app-links {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    margin-bottom: 2rem;
    cursor:pointer
}


#api-status {
    font-size: 0.7rem
}

.social-links i:nth-child(1):hover {
    color: #1877f2
}
.social-links i:nth-child(2):hover {
    color: #0077B5
}
.social-links i:nth-child(3):hover {
    color: #FF0000
}
.social-links i:nth-child(4):hover {
    color:  #1DA1F2
}
