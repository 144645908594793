.service-container {
    width: 100%;
    height: auto;
    padding: 2rem 0 3rem 0;
    background-color: white;
}

.service {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.service-bottom {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.service_image_overlay {
    display: flex;
    justify-content: center;
    width: 40%;
    position: relative;
    overflow: hidden;
    border-radius: 2rem;
}


#computer {
    object-fit: contain;
    -webkit-mask:
        linear-gradient(to top, transparent 1%, #fff 15% 90%, transparent 99%),
        linear-gradient(to left, transparent 1%, #fff 15% 90%, transparent 99%);
    -webkit-mask-size: 110% 110%;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-composite: source-in;

    mask:
        linear-gradient(to top, transparent 1%, #fff 15% 90%, transparent 99%),
        linear-gradient(to left, transparent 1%, #fff 15% 89%, transparent 99%);
    mask-size: 110% 110%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-composite: intersect;
    /*
    linear-gradient(90deg,
        transparent 0%,
        rgba(0, 0, 0, 0.3) 10%,
        rgba(0, 0, 0, 0.3) 90%,
        transparent 100%
    ),

    linear-gradient(180deg,
        transparent 0%,
        rgba(0, 0, 0, 0.3) 10%,
        rgba(0, 0, 0, 0.3) 90%,
        transparent 100%
    ),

    linear-gradient(0deg,
        transparent 0%,
        rgba(0, 0, 0, 0.3) 10%,
        rgba(0, 0, 0, 0.3) 90%,
        transparent 100%
    ); */
}


.service-bottom div ul {
    padding: 0px;
}

.service-bottom div ul li {
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 1rem;
}

.service-bottom div ul li span {
    font-size: 1.7rem;
    font-weight: bold;
    border-bottom: 3px solid #4caf50;
}

@media (max-width: 645px) {

    .service-bottom {
        flex-direction: column;
    }

    .service_image_overlay {
        width: 100%;
        margin-bottom: 1rem;
    }

    .service-top {
        padding-bottom: 0;
    }
}
