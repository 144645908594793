.hero-container {
    position: relative;
    width: 100%;
    height: 55vh;
    background-image: url("../../assets/einstein_stuff/fancy_graph.png");

}

.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgb(22, 21, 21), 70%, rgba(255, 0, 0, 0));
    z-index: 1;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
}

.hero-left {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;
    filter:
}

.hero-left h1 {
    margin: 0 0 0.3rem 0;
    font-size: 3rem
}

.hero-left p  {
    margin: 0;
    font-style: italic;
}

.hero-left h3 {
    margin: 0.3rem 0 1rem 0 ;
    color: #f2f2f2;
    font-size: 2rem
}

.hero-left a {
    display: flex;
    background-color: #EEC61F;
    width: 130px;
    height: 40px;;
    border-radius: 20px;
    font-size: 0.9rem;
    color: white;
    justify-content: center;
    align-items: center;
}
