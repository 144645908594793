body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  min-height: 100%;
  overflow-x: hidden;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

html {
  box-sizing: border-box;
  background-color: #f2f2f2;
  margin: 0;
  min-height: 100%;
  width: 100vw;
  overflow-y: overlay;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer
}


textarea {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  resize: none;
  outline: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

* {
  font-family: poppins;
}

@font-face {
  font-family: "AmaticBold";
  src: url('./components/assets/fonts/AmaticSC-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "AmaticRegular";
  src: url('./components/assets/fonts/AmaticSC-Regular.ttf') format('truetype');
}
