.service_details > span {
    position: absolute;
    top: 5%;
    right: 10%;
    cursor: pointer;
    transition: color 0.3s ease;
}

.service_details > span:hover {
    color: gray;
}

.service_details {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1rem 3rem;
}


.service_details_subitems {
    color: rgb(78, 78, 78);
    padding: 0.3rem 0;
}

.service_details_header {
    font-weight: bold;
}
