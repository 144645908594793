.integrate-container {
    width: 100%;
    height: auto;
    padding: 2rem 0 5rem 0;
}

.integrate {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.integrate-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
    gap: 3rem;

}

.miguel-intro {
    display: flex;
    gap: 3rem;
    width: 100%;
}

.miguel-img-container {
    width: 45%;
}

.miguel-img-container>img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.miguel-intro-paragraph-container {
    width: 50%;
}

.marilo-intro {
    display: flex;
    flex-direction: row-reverse;
    gap: 3rem;
    width: 100%;
}

.marilo-img-container {
    width: 45%;
}

.marilo-img-container>img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.marilo-intro-paragraph-container {
    width: 50%;
}


@media (max-width: 645px) {

    .miguel-intro {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }


    .miguel-intro > div {
        width: 90%;
    }


    .marilo-intro {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }


    .marilo-intro > div {
        width: 90%;
    }


}
