.finalcta-container {
    width: 100%;
    height: auto;
    padding: 2rem 0 3rem 0;
    background-color: #2c2a28;
}

.finalcta {
    width: 80%;
    max-width: 80rem;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.finalcta h3 {
    margin-bottom: 3rem;
}

.finalcta h1 span {
    border-bottom: 3px solid white;
    transition: all 0.6s ease
}

.finalcta h1 span:hover {
    border-bottom: 3px solid #57ce5b;
}

.finalcta a {
    color: white;
    background-color:  #EEC61F;
    padding: 1rem;
    border-radius: 12px;
    transition: all 0.6s ease
}

.finalcta a:hover {
    background-color: #6dc170;
}
